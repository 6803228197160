export default () => {
  const scrollToTop = (top = 0) => {
    if (process.client) {
      window.scroll({
        top,
      });
    }
  };

  return {
    scrollToTop,
  };
};
