<template>
  <div
    class="d-unset cursor-pointer"
    @click.exact.left="sameWindow(link)"
    @click.exact.middle="newWindow(link)"
    @click.meta="newWindow(link)"
    @click.ctrl="newWindow(link)"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  name: 'LinkHandler',
  props: {
    link: {
      type: String,
      required: true,
    },
    external: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();

    const newWindow = (url: string) => {
      window.open(url, '_blank');
    };

    const sameWindow = (url: string) => {
      if (props.external) {
        window.open(url, '_self');
      } else {
        router.push({
          path: url,
        });
      }
    };

    return {
      newWindow,
      sameWindow,
    };
  },
});
</script>
